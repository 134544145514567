import { removeCurUser, setAuthStatus } from "shared/storage";

import { BASE_URL, LOGIN_ERROR, REQ_TIMEOUT } from "./consts";
import { FetchError } from "./errors";
import note from "shared/note";

type SpecialOptions<T extends boolean = true> = Partial<{
  baseUrl: string,
  requestTimeout: number,
  headers: HeadersInit,
  isJson: T,
}>;
type FetchFullOptions<T extends boolean = true> = RequestInit & SpecialOptions<T>;
export type FetchOptions<T extends boolean = true> = Omit<RequestInit, 'method' | 'headers' | 'body' | 'signal'> & SpecialOptions<T>;

export type BodyMethod = 'POST' | 'PUT' | 'PATCH';
export type BodilessMethod = 'GET' | 'DELETE';
export type Method = BodilessMethod | BodyMethod;

const getFullUrl = (url: string | URL, options: { baseUrl?: string }) =>
  url instanceof URL ? url : new URL(`${options.baseUrl ?? BASE_URL}${url}`);

const executeMethod = async <T extends boolean = true>(
  method: Method,
  url: URL,
  options: FetchFullOptions<T>,
): Promise<T extends true ? Record<string, any> : Response> => {
  const controller = new AbortController();
  options.method = method;
  options.signal = controller.signal;
  (options.isJson as any) ??= true;
  options.credentials ??= 'include';

  const timeoutId = setTimeout(
    () => controller.abort(),
    options.requestTimeout !== undefined ? options.requestTimeout : REQ_TIMEOUT
  );

  try {
    const response = await fetch(url, options);
    clearTimeout(timeoutId);

    if (!options.isJson && response.status < 400) return response;

    const data = ((await response.json()) || {}) as Record<string, any>;
    if (response.status < 400) return data as any;
    if (!data?.error) throw new FetchError();

    const error = data.error;
    if (!error?.code) throw new FetchError();
    if (error.code !== LOGIN_ERROR) throw new FetchError(error);

    removeCurUser();
    setAuthStatus(false);
    await window.router.navigate('/', { replace: true });
    throw new FetchError(error);

  } catch (error: any) {
    console.error(error);
    if (error?.name !== 'AbortError') clearTimeout(timeoutId);
    else throw new FetchError({ message: 'Сбой или отсутствие подключения к сети. Проверьте соединение и/или повторите позже.' });
    if (!error?.code || !error?.message) throw new FetchError();
    throw new FetchError(error);
  }
};

export const buildBodyMethod = <T extends boolean, TMethod extends BodyMethod>(method: TMethod) =>
  (url: string | URL, data?: BodyInit | Record<string, any> | null, options: FetchOptions<T> = {}) => {
    const isFormData = data instanceof FormData;

    const headers = new Headers(options.headers);
    if (isFormData)
      headers.delete('Content-Type');
    else if (!headers.has('Content-Type'))
      headers.set('Content-Type', 'application/json');

    (options as RequestInit).headers = headers;
      
    if (data && !isFormData && !(data instanceof Blob))
      data = JSON.stringify(data);

    (options as RequestInit).body = data as BodyInit;
    

    return executeMethod(method, getFullUrl(url, options), options);
  };

export const buildBodilessMethod = <T extends boolean, TMethod extends BodilessMethod>(method: TMethod) =>
  (url: string | URL, data?: Record<string, any> | null, options: FetchOptions<T> = {}) => {
    const fullUrl = getFullUrl(url, options);

    if (data)
      for (const [key, value] of Object.entries(data)) {
        if (value === undefined || value === null)
          continue;

        fullUrl.searchParams.set(key, value);
      }

    return executeMethod(method, fullUrl, options);
  };

export const buildExpectMethod = <TMethodFn extends (...args: any) => Promise<any>>(
  methodFn: TMethodFn,
) => async (
  ...args: Parameters<TMethodFn>
): Promise<Awaited<ReturnType<TMethodFn>> | undefined> => {
  try {
    const result = await methodFn(...args);
    return result;

  } catch (error: any) {
    if (error?.code && error?.message)
      note(error.code, { content: error.message });

    return undefined;
  }
};
