import { pick } from 'shared/utils';

import { roundNumber } from './utils';

const KEYS = [
  'id',
  'uuid',
  'width',
  'height',
  'length',
  'openings',
  'perimeter',
  'perimeterManual',
  'areaFloor',
  'areaFloorManual',
  'areaWalls',
  'areaWallsManual',
  'sum',
  'isBathroom',
  'coefficient',
  'outletNumber',
  'slope',
] as const;

export const roomSerializer = (room: Record<string, any>) => pick(room, KEYS, roundNumber);
