import { openDB, OpenDBCallbacks } from 'idb';

import { requestExpect } from 'shared/utils';

import { updateStoresFactory } from '../helpers';

import { STATIC_IDB_STORE_NAMES } from './consts';
import { StaticIdbSchema } from './types';

export const initStaticIdb = async (initCallbacks: OpenDBCallbacks<StaticIdbSchema> = {}) => {
  const data = await requestExpect.get('/context/static') as Record<string, any>;

  if (!initCallbacks.upgrade) Object.assign(initCallbacks, {
    upgrade: updateStoresFactory(STATIC_IDB_STORE_NAMES, data),
  });

  return openDB<StaticIdbSchema>(
    'context-static',
    data?.version,
    initCallbacks,
  );
};
