import { deleteDB, openDB, OpenDBCallbacks } from 'idb';

import { requestExpect } from 'shared/utils';

import { updateStoresFactory } from '../helpers';

import {
  MAX_CACHE_SIZE,
  VERSION_IDB_STORE_NAMES,
  VER_STORE_PREFIX,
} from './consts';
import { VersionIdbSchema } from './types';

const getStoredVersions = async () => {
  const entries = await indexedDB.databases();

  return entries
    .filter((entry) => entry.name!.includes(VER_STORE_PREFIX))
    .map((entry) => +entry.name!.replace(VER_STORE_PREFIX, ''))
    .sort((a, b) => b - a);
};

const openVersionIdb = (
  contextVersion: number,
  initCallbacks: OpenDBCallbacks<VersionIdbSchema>
) => openDB(
  `${VER_STORE_PREFIX}${contextVersion}`,
  1,
  initCallbacks,
);

export const initVersionIdb = async (
  idbVersion: number | 'latest' = 'latest',
  initCallbacks: OpenDBCallbacks<VersionIdbSchema> = {}
) => {
  const existingVersions = await getStoredVersions();
  if (idbVersion !== 'latest' && existingVersions.includes(idbVersion))
    return openVersionIdb(idbVersion, initCallbacks);

  const data = await requestExpect.get(`/context/version/${idbVersion}`) as Record<string, any>;;
  const dataVersion: { id: number; [key: string]: any } | undefined = data?.version;

  const curVersion =
    idbVersion === 'latest' ? (dataVersion?.id || existingVersions[0]) : idbVersion;

  if (!initCallbacks.upgrade) Object.assign(initCallbacks, {
    upgrade: updateStoresFactory(VERSION_IDB_STORE_NAMES, dataVersion),
  });

  const idb = await openVersionIdb(curVersion, initCallbacks);
  if (existingVersions.includes(curVersion)) return idb;

  if (existingVersions.length > MAX_CACHE_SIZE) {
    const excessVersions = existingVersions.slice(MAX_CACHE_SIZE);
    for (const version of excessVersions) {
      if (version === curVersion) continue;
      deleteDB(`${VER_STORE_PREFIX}${version}`);
    }
  }

  return idb;
};
