import {
  DEAL_ADD_NEW_OUTLAY,
  DEAL_CHANGE_SELECTED,
  DEAL_COPY_OUTLAY,
  DEAL_DELETE_OUTLAY,
  DEAL_EDIT_DATA,
  DEAL_EDIT_OUTLAY,
  DEAL_FILL_LIST,
  DEAL_OUTLAY_CHANGE_ID,
  DEAL_SET_CLIENT_INFO,
} from '../constants';

const initialState = {
  dealList: [],
  selectedDeal: null,
  pageCount: 1,
  clientInfo: null,
};

export const dealReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case DEAL_FILL_LIST:
      return {
        ...state,
        dealList: [...payload.data.deals],
        pageCount: payload.data.pageCount || 1,
        selectedDeal: null,
      };
    case DEAL_CHANGE_SELECTED:
      return {
        ...state,
        selectedDeal: payload.data.deal || null,
        clientInfo: payload.data.clientInfo || null,
      };
    case DEAL_EDIT_DATA: return {
      ...state,
      selectedDeal: {
        ...state.selectedDeal,
        ...payload.data,
        client: {
          ...state.selectedDeal.client,
          ...payload.data.client,
        },
        brand: {
          ...state.selectedDeal.brand,
          ...payload.data.brand,
        },
      },
    };
    case DEAL_ADD_NEW_OUTLAY:
      // return {
      //   ...state,
      //   selectedDeal: {
      //     ...state.selectedDeal,
      //     outlays: [...state.selectedDeal.outlays, payload.data],
      //   },
      // };
    case DEAL_COPY_OUTLAY: {
      let isFinal = false;
      if (payload.data.type === 'outlay') {
        // const outlays = state.selectedDeal.outlays.filter((outlay) => outlay.type === 'outlay');
        const outlayCount = state.selectedDeal.outlays.reduce((res, outlay) => res + (outlay.type === 'outlay'), 0);
        isFinal = outlayCount === 0 ? true : !!payload.data.isFinal;
      }

      const outlays = isFinal
        ? state.selectedDeal.outlays.map((outlay) => ({ ...outlay, isFinal: false }))
        : state.selectedDeal.outlays;

      return {
        ...state,
        selectedDeal: {
          ...state.selectedDeal,
          outlays: [...outlays, { ...payload.data, isFinal }],
        },
      };
    }
    case DEAL_OUTLAY_CHANGE_ID: return {
      ...state,
      selectedDeal: {
        ...state.selectedDeal,
        outlays: state.selectedDeal.outlays.map((outlay) => {
          if (outlay.id !== payload.prevId && outlay.uuid !== payload.prevId) return outlay;
          return {
            ...outlay,
            id: payload.newId,
            // number: payload.outlay.number,
            // isFinal: payload.outlay.isFinal,
          };
        }),
      },
    };
    case DEAL_DELETE_OUTLAY: {
      if (!(state.selectedDeal && state.selectedDeal.outlays)) return state;
      return {
        ...state,
        selectedDeal: {
          ...state.selectedDeal,
          outlays: state.selectedDeal.outlays.filter(
            (item) => item.id !== payload.outlayId && item.uuid !== payload.outlayId
          ),
        },
      };
    }
    case DEAL_EDIT_OUTLAY: {
      let sum = null;
      let dealId = null;
      const isOutlay = payload.type === 'outlay';
      const isFinal = payload.data.isFinal;
      const outlays = state.selectedDeal.outlays.map((outlay) => {
        if (outlay.id !== payload.outlayId && outlay.uuid !== payload.outlayId) {
          if (isOutlay && outlay.type !== 'outlay') return outlay;
          if (!isOutlay && outlay.type !== 'act') return outlay;
          return isFinal ? { ...outlay, isFinal: !isFinal } : outlay;
        }

        if (isOutlay && payload.data.isFinal) {
          sum = payload.data.sum;
          dealId = payload.data.dealId;
        }

        return { ...outlay, ...payload.data };
      });

      let selectedDeal = { ...state.selectedDeal, outlays };
      let dealList = state.dealList;
      if (sum && dealId) {
        selectedDeal.sum = sum;

        if (dealList) {
          dealList = dealList.map((deal) => deal.id !== dealId ? deal : { ...deal, sum });
        }
      }

      return { ...state, selectedDeal, dealList };
    }
    case DEAL_SET_CLIENT_INFO:
      return { ...state, clientInfo: payload.data };
    default:
      break;
  }
  return state;
};
