import { getSetMap } from 'shared/utils';
import { add } from 'shared/big';

import './rectifyRoomsTypeDef';
import {
  getWorkFilter,
  workSorter,
  // workChunker,
  roomFilter,
  prepareRoom,
  getWorkMapper,
  // workSumMapper,
} from './helpers';

/**
 * @arg {Room[]} rooms
 * @arg {{ id: number, name: string }[]} workTypes
 * @arg {{ id: number, name: string }[]} matTypes
 * @arg {number} [actNumber=]
 * @returns {RectifiedRoom[]}
 */

export const rectifyRooms = (rooms, workTypes, matTypes, actNumber) => {
  const workFilter = getWorkFilter(actNumber);
  const workMapper = getWorkMapper(workTypes, matTypes, actNumber);

  const roomMapper = (room) => {
    const roomName = room.roomType?.name || room.name || 'Помещение';

    const works = room.roomPositions.filter(workFilter).sort(workSorter).map(workMapper);
    // const works = chunkBy(preparedWorks, workChunker).map(workSumMapper);

    const workTreeMap = new Map();
    const workList = [];
    for (const work of works) {
      work.room = roomName;
      const workTypeRef = getSetMap(workTreeMap, work.workType, new Map());
      const matTypeRef = getSetMap(workTypeRef, work.matType, []);
      matTypeRef.push(work);
      workList.push(work);
    }

    const workTree = [];
    let roomSum = 0;
    let roomFullSum = 0;
    let roomDiscountSum = 0;
    let roomMasterWage = 0;
    let roomShiftWage = 0;
    let roomBonus = 0;
    for (const [workType, matTypes] of workTreeMap.entries()) {
      workTree.push({
        name: workType,
        room: roomName,
        matTypes: [],
        sum: 0,
        fullSum: 0,
        discountSum: 0,
        masterWage: 0,
        shiftWage: 0,
        bonus: 0,
        key: workType.slice(0, 6),
      });
      const workTypeRef = workTree[workTree.length - 1];

      for (const [matType, works] of matTypes.entries()) {
        workTypeRef.matTypes.push({
          name: matType,
          room: roomName,
          works: [],
          sum: 0,
          fullSum: 0,
          discountSum: 0,
          masterWage: 0,
          shiftWage: 0,
          bonus: 0,
          key: `${workType.slice(0, 6)}-${matType.slice(0, 6)}`,
        });
        const matTypeRef = workTypeRef.matTypes[workTypeRef.matTypes.length - 1];

        for (const work of works) {
          matTypeRef.works.push(work);
          workTypeRef.id = work.workTypeId;
          matTypeRef.id = work.materialTypeId;

          roomSum = add(roomSum, work.sum);
          (roomFullSum = add(roomFullSum, work.fullSum)),
            (roomDiscountSum = add(roomDiscountSum, work.discountSum));
          roomMasterWage = add(roomMasterWage, work.masterWage);
          roomShiftWage = add(roomShiftWage, work.shiftWage);
          roomBonus = add(roomBonus, work.bonus);
          workTypeRef.sum = add(workTypeRef.sum, work.sum);
          workTypeRef.fullSum = add(workTypeRef.fullSum, work.fullSum);
          workTypeRef.discountSum = add(workTypeRef.discountSum, work.discountSum);
          workTypeRef.masterWage = add(workTypeRef.masterWage, work.masterWage);
          workTypeRef.shiftWage = add(workTypeRef.shiftWage, work.shiftWage);
          workTypeRef.bonus = add(workTypeRef.bonus, work.bonus);
          matTypeRef.sum = add(matTypeRef.sum, work.sum);
          matTypeRef.fullSum = add(workTypeRef.fullSum, work.fullSum);
          matTypeRef.discountSum = add(matTypeRef.discountSum, work.discountSum);
          matTypeRef.masterWage = add(matTypeRef.masterWage, work.masterWage);
          matTypeRef.shiftWage = add(matTypeRef.shiftWage, work.shiftWage);
          matTypeRef.bonus = add(matTypeRef.bonus, work.bonus);
        }
      }
    }

    return prepareRoom(
      room,
      roomSum,
      roomFullSum,
      roomDiscountSum,
      roomMasterWage,
      roomShiftWage,
      roomBonus,
      workTree,
      workList
    );
  };

  return rooms.filter(roomFilter).map(roomMapper);
};
