import { pick } from 'shared/utils';

import { roundNumber } from './utils';

const KEYS = [
  'id',
  'dealId',
  'isFinal',
  'sum',
  'fullSum',
  'categorySumNP',
  'categorySumSpecial',
  'categorySumCleaning',
  'masterWageSum',
  'masterWageSumNP',
  'masterWageSumSpecial',
  'masterWageSumCleaning',
  'shiftWageSum',
  'maxDiscount',
  'maxDiscountValue',
  'discount',
  'discountValue',
  'promotionSum',
  'promotionPercent',
  'totalDiscount',
  'totalDiscountValue',
  'remainingDiscount',
  'remainingDiscountValue',
  'isDiscountValid',
  'technologyDisclaimer',
  'workTypeConstraintViolations',
] as const;

export const outlaySerializer = (outlay: Record<string, any>) => pick(outlay, KEYS, roundNumber);
