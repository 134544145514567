export const pullBy = <T extends string | number | any[] | Record<string, any>>(
  arr: T[],
  predicate: (value: T, index: number, array: T[]) => boolean
): [T[], T[]] => {
  const pulled: T[] = [];
  const result = arr.filter((value, index, array) => {
    if (predicate(value, index, array)) return true;
    pulled.push(value);
    return false;
  });
  return [result, pulled];
};
