import { useMemo } from 'react';

import styles from './CardFiller.module.scss';

interface CardFillerLayout {
  key: string,
  label: string,
  transform?: (value: any, record: Record<string, any>) => any,
  render?: (value: any, record: Record<string, any>) => React.ReactNode,
  isLabelRow?: boolean,
  subLayout?: CardFillerLayout[],
}

interface CardFillerProps {
  layout: CardFillerLayout[],
  data: Record<string, any>,
  isSubLayout: boolean,
}

const normalize = (
  data: Record<string, any>,
  key: string,
  transform?: CardFillerLayout['transform'],
) => {
  if (!data || typeof data !== 'object') return null;

  const value = data[key];
  if (typeof transform !== 'function') return value;

  try {
    const result = transform(value, data);
    return result;
    
  } catch (err) {
    return null;
  }
};

const rerender = (value: any, data: Record<string, any>, render: CardFillerLayout['render']) => {
  if (typeof render !== 'function') return null;
  return render(value, data || {});
};

const CardFillerRow: React.FC<{ layout: CardFillerLayout, data: Record<string, any> }> = ({
  layout: { key, label, transform, render, isLabelRow },
  data,
}) => {
  const value = useMemo(() => normalize(data, key, transform), [data, key, transform]);
  const rendition = useMemo(() => rerender(value, data, render), [value, data, render]);

  return (
    <li
      key={`ROW-${key}`}
      data-label-row={!!isLabelRow}
      title={typeof value === 'string' ? value : ''}
    >
      <span>{`${label}:\u{2009}`}</span>
      {rendition ? (
        <div>{rendition}</div>
      ) : (
        <p>{value || '-'}</p>
      )}
    </li>
  );
};

export const CardFiller: React.FC<CardFillerProps> = ({ layout, data, isSubLayout = false }) => (
  <ul className={styles.Container} data-sub={isSubLayout}>
    {layout.map((row) => {
      if (row.subLayout) return <CardFiller key={row.key} isSubLayout layout={row.subLayout} data={data} />;
      return <CardFillerRow key={row.key} layout={row} data={data} />;
    })}
  </ul>
);
