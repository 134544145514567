import { useSelector } from 'react-redux';

import { getUserRole, isDealStatusAct, isUserAct, isUserParent, ROLES } from 'shared/access';
import { getCurUser } from 'shared/storage';

const LOCKED = true;
const UNLOCKED = false;

export const useIsLocked = (): boolean => {
  const user = getCurUser();
  const selectedDeal = useSelector<any, any>((state) => state.deal.selectedDeal || null) as Record<string, any> | null;
  const selectedOutlay = useSelector<any, any>((state) => state.outlay.selectedOutlay || null) as Record<string, any> | null;

  if (!user) return LOCKED;
  if (!selectedDeal || !selectedOutlay) return LOCKED;
  const role = getUserRole(user);
  if (role === ROLES.admin) return UNLOCKED;
  
  if (selectedOutlay.type === 'act' && selectedDeal.isActLocked) return LOCKED;
  if (selectedOutlay.type === 'outlay' && selectedDeal.isOutlayLocked) {
    if (!isUserAct(user)) return LOCKED;
    if (!isDealStatusAct(selectedDeal as any)) return LOCKED;
  }

  const isUserAuthor = selectedOutlay.authorUserId === user.id;
  if (isUserAuthor) return UNLOCKED;

  const isParent = isUserParent(user);
  const isAuthorChildUser = isParent ? !!user.childUsers?.some(({ id }) => id === selectedOutlay.authorUserId) : false;
  if (isAuthorChildUser) return UNLOCKED;

  if (role === ROLES.chiefEstimator || role === ROLES.chiefForeman) return LOCKED;
  return isParent ? UNLOCKED : LOCKED;
};
