import { actSerializer, outlaySerializer, roomSerializer, workSerializer } from './serializers';

export const rectifyResult = (
  outlay: Record<string, any> = {},
  rooms: Record<string, any>[] = [],
  type: 'outlay' | 'act' = 'outlay',
  subActs: Record<string, any>[] = [],
) => {
  const result: Record<string, any> = outlaySerializer(outlay);

  result.rooms = rooms.map((room = {}) => {
    const { roomPositions = [], ...roomValues } = room;

    const roomResult: Record<string, any> = roomSerializer(roomValues);

    roomResult.roomPositions = roomPositions
      .filter((work: Record<string, any> = {}) => !work.isSurrogate)
      .map((work: Record<string, any>) => {
        const workResult: Record<string, any> = workSerializer(work);
  
        for (const [key, value] of Object.entries(workResult)) {
          if (!key.endsWith('Calculated')) continue;
          workResult[key.replace('Calculated', '')] = value;
        }
  
        return workResult;
      });
  
      return roomResult;
    });

  if (type === 'act') {
    result.discount = outlay.discountPercent;
    delete result.discountPercent;
    result.subActs = subActs
      .sort((a, b) => +a.actNumber - +b.actNumber)
      .map((act) => actSerializer(act));
  }

  return result;
};
