import { io } from 'socket.io-client';

import { REQ_TIMEOUT, SOCKET_PATH, SOCKET_URL } from './consts';
import { buildBodilessMethod, buildBodyMethod, buildExpectMethod } from './method';

export * from './consts';

export const request = {
  get:    buildBodilessMethod('GET'),
  delete: buildBodilessMethod('DELETE'),
  post:   buildBodyMethod('POST'),
  put:    buildBodyMethod('PUT'),
  patch:  buildBodyMethod('PATCH'),
} as const;

export const requestExpect = {
  get:    buildExpectMethod(request.get),
  delete: buildExpectMethod(request.delete),
  post:   buildExpectMethod(request.post),
  put:    buildExpectMethod(request.put),
  patch:  buildExpectMethod(request.patch),
} as const;

export const socket = io(SOCKET_URL, {
  autoConnect: false,
  rememberUpgrade: true,
  transports: ['websocket'],
  withCredentials: true,
  path: SOCKET_PATH,
  timeout: REQ_TIMEOUT,
  ackTimeout: REQ_TIMEOUT,
});
