import { EmbeddedActionsParser } from 'chevrotain';

import { isParsable, logger, perf } from 'shared/utils';

import { LOCAL_VAR_COL_ROW_RGX, COL_PROP_MAP, DEAL_PROPS, OutlayVar, OUTLAY_VAR_MAP, ROOM_PROPS } from '../const';

import { COMP_OP_MAP, CompOpKey, ADD_OP_MAP, AddOpKey, MUL_OP_MAP, MulOpKey } from './const';
import { Bool, Comma, Float, GlobalVar, IfFunction, LocalVar, LParen, RParen, allTokens, lex, CompOp, AddOp, MulOp } from './lexer';

export interface VariableContext {
  work: { position: { [key: string]: string | number }, [key: string]: any },
  room: Record<string, any>,
  outlay: Record<string, any>,
  deal: Record<string, any>,
  scale?: number;
}

const ZERO_WRAPPED = "'0'";
const ONE_WRAPPED = "'1'";

const wrapValue = (value?: boolean | string | number | import('shared/big').Big) => {
  if (value === 'true' || value === 'false') return value;
  if (value === true || value === false) return value.toString();
  return value?.toString ? `'${value}'` : ZERO_WRAPPED;
};

export class Parser extends EmbeddedActionsParser {
  public ctx?: VariableContext = undefined;
  private isCtxSet(ctx?: VariableContext): asserts ctx is VariableContext {
    if (!ctx) throw Error('Variable Context has not been set');
  }

  public expression = this.RULE('expression', (): string => this.SUBRULE(this.compExpression));

  private compExpression = this.RULE('compExpression', () => {
    let result: string | undefined;

    const lhe = this.SUBRULE(this.addExpression);
    this.OPTION(() => {
      const op = COMP_OP_MAP[this.CONSUME(CompOp).image as CompOpKey];
      const rhe = this.SUBRULE1(this.addExpression);

      // TODO: Look into performance/rigor of this simplification
      // IF function can be simplified even further with this hack
      // if (op === 'eq') result = lhe === rhe ? 'true' : 'false';
      result = `${op}(${lhe},${rhe})`;
    });

    return result || lhe;
  });

  private addExpression = this.RULE('addExpression', () => {
    let result: string | undefined;

    const lhe = this.SUBRULE(this.mulExpression);
    this.MANY(() => {
      const op = ADD_OP_MAP[this.CONSUME(AddOp).image as AddOpKey];
      const rhe = this.SUBRULE1(this.mulExpression);

      if (rhe === ZERO_WRAPPED) return;
      if (result) result += `.${op}(${rhe})`;
      else result = `${op}(${lhe},${rhe})`;
    });

    return result || lhe;
  });

  private mulExpression = this.RULE('mulExpression', () => {
    let result: string | undefined;

    const lhe = this.SUBRULE(this.atomicExpression);
    this.MANY(() => {
      const op = MUL_OP_MAP[this.CONSUME(MulOp).image as MulOpKey];
      const rhe = this.SUBRULE1(this.atomicExpression);

      if (rhe === ONE_WRAPPED) return;
      if (result) result += `.${op}(${rhe})`;
      else result = `${op}(${lhe},${rhe})`;
    });

    return result || lhe;
  });

  private atomicExpression = this.RULE('atomicExpression', () => this.OR([
    { ALT: () => this.SUBRULE(this.parenExpression) },
    { ALT: () => this.SUBRULE(this.ifFunction) },
    { ALT: () => this.SUBRULE(this.variable) },
    { ALT: () => this.SUBRULE(this.constant) },
  ]));

  private parenExpression = this.RULE('parenExpression', () => {
    this.CONSUME(LParen);
    const expression = this.SUBRULE(this.expression);
    this.CONSUME(RParen);

    return expression;
  });

  private resolveGlobalVar(globalVar: string, prop: string): string {
    this.isCtxSet(this.ctx);

    if (DEAL_PROPS.includes(globalVar)) return wrapValue(this.ctx.deal[prop]);
    if (ROOM_PROPS.includes(globalVar)) return wrapValue(this.ctx.room[prop]);
    return wrapValue(this.ctx.outlay[prop]);
  }

  private resolveLocalVar(row: number, prop: string): string {
    this.isCtxSet(this.ctx);

    const work = this.ctx.work.position.excelRow === row
      ? this.ctx.work
      : this.ctx.work.room?.roomPositions?.find(({ position }: any) => position.excelRow === row);

    if (!work) return ZERO_WRAPPED;
    if (prop === 'basePrice') return wrapValue(work.priceManual ?? work.position.basePrice);
    
    const manualProp = `${prop}Manual`;
    if (prop !== 'price' && isParsable(work[manualProp])) return wrapValue(work[manualProp]);

    const calcedProp = `${prop}Calculated`;
    const readyProp = `${prop}Ready`;
    if (work[calcedProp] && work[readyProp]) return wrapValue(work[calcedProp]);

    const formulaProp = `${prop}Formula`;
    if (!work.position[formulaProp]) return wrapValue(work.position[prop]);

    const result = work[`${prop}Getter`]();
    work[calcedProp] = result;
    work[readyProp] = true;

    return wrapValue(result);
  }

  private variable = this.RULE('variable', () => this.OR([
    { ALT: () => {
      const globalVar = this.CONSUME(GlobalVar).image;
      const prop = OUTLAY_VAR_MAP[globalVar as OutlayVar];

      return this.ACTION(() => this.resolveGlobalVar(globalVar, prop));
    } },
    { ALT: () => {
      const localVar = this.CONSUME(LocalVar).image;
      const [strCol, strRow] = localVar.match(LOCAL_VAR_COL_ROW_RGX) || [];

      const row = +strRow;
      const prop = COL_PROP_MAP[strCol as keyof typeof COL_PROP_MAP];

      return this.ACTION(() => this.resolveLocalVar(row, prop));
    } },
  ]));

  private constant = this.RULE('constant', () => this.OR([
    { ALT: () => this.CONSUME(Bool).image === 'TRUE' ? 'true' : 'false' },
    { ALT: () => wrapValue(this.CONSUME(Float).image) },
  ]));

  private ifFunction = this.RULE('ifFunction', () => {
    this.CONSUME(IfFunction);
    const condition = this.SUBRULE(this.expression);
    this.CONSUME(Comma);
    const trueExpression = this.SUBRULE1(this.expression);
    this.CONSUME1(Comma);
    const falseExpression = this.SUBRULE2(this.expression);
    this.CONSUME(RParen);

    if (condition === 'true') return trueExpression;
    if (condition === 'false') return falseExpression;
    return `${condition}?${trueExpression}:${falseExpression}`;
  });

  constructor() {
    super(allTokens, {
      maxLookahead: 1,
      skipValidations: __APP_ENV__ === 'production',
    });
    
    this.performSelfAnalysis();
  }
}

const parser = new Parser();

export const parse = (input: string, ctx: VariableContext) => {
  const timer = perf('lexer / parser');
  if (input.startsWith('='))
    input = input.slice(1);
  input = input.toUpperCase();

  const result = lex(input);
  if (result.errors.length) {
    timer();
    logger.error('lexer', result.errors);
    return () => 0;
  }

  parser.ctx = ctx;
  parser.input = result.tokens;
  const expression = parser.expression();
  if (parser.errors.length) {
    timer();
    logger.error('parser', parser.errors);
    return () => 0;
  }

  const callback = new Function(`'use strict';return ${expression}`);
  
  timer();
  return callback;
};
