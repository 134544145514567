interface PickPredicate<S extends Record<string, any>, K extends readonly [(keyof S), ...(keyof S)[]]> {
  (value: S[K[number]], key: K[number]): any;
}

const defaultPredicate = (value: any) => value;

export const pick = <S extends Record<string, any>, K extends readonly [(keyof S), ...(keyof S)[]]>(
  source: S,
  keys: K,
  predicate: PickPredicate<S, K> = defaultPredicate,
): Pick<S, K[number]> => {
  const target = {} as Pick<S, K[number]>;

  for (let idx = 0; idx < keys.length; idx++) {
    const key = keys[idx];
    if (!(key in source)) continue;
    target[key] = predicate(source[key], key);
  }

  return target;
};
